<div class="container" *ngIf="inscription && config; else loader">

  <mat-card *ngIf="config.infoMessage" [portailCustomStyle]="config.styleCardInfo">
    <div [innerHtml]="config.infoMessage | safe"></div>
  </mat-card>

  <ng-container *ngFor="let discNiv of inscription.depDiscNivGroup">
    <div *ngIf="discNiv.disciplineReservable && discNiv.etatRenouvellement !== ''"
      class="list-discipline mat-elevation-z2">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div style="font-weight: 500; margin-left: 5px;">
          <app-icon style="margin-right: 5px;" type="do" icon="departement"></app-icon>
          <span *ngIf="discNiv.departementName">
            {{ discNiv.departementName }}<span class="separator">-</span>
          </span>
          <span>{{ discNiv.disciplineName }}<span class="separator">-</span>
          </span>
          <span>{{ discNiv.niveauName }}</span>
        </div>
        <div>
          <label class="label">Renouveler : </label>
          <mat-radio-group *ngIf="discNiv.disciplineReservable" [(ngModel)]="discNiv.renewal"
            (change)="radioChange($event, discNiv)">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="discNiv.renewal === 'oui'">

        <div *ngIf="discNiv.availableCours?.length" class="courses-container">

          <p class="text-center">{{'lasido.edit.order_courses'|trans}}</p>

          <div *ngIf="discNiv.choicesCours?.length" class="courses-list">

            <div cdkDropList class="courses-drop-list" (cdkDropListDropped)="onDropCourse($event, discNiv)">
              <div class="course-drag-item" *ngFor="let cours of discNiv.choicesCours; let i = index"
                cdkDragLockAxis="y" cdkDrag>
                <div class="courses-index-list">
                  <span>{{ i + 1 }}.</span>
                </div>
                <span>{{ cours.libelle }}
                  <span *ngIf="!cours.provisoire; else coursProvisoire" class="details-cours">
                    (Le {{cours.jour}} de
                    {{cours.heureDebut}} à
                    {{cours.heureFin}})
                  </span>
                  <ng-template #coursProvisoire>
                    <span class="details-cours">({{'lasido.text-cours-provisoire' | trans}})</span>
                  </ng-template>
                </span>
                <div class="courses-delete-list">
                  <span>
                    <mat-icon color="warn" [matTooltip]="'action.delete'|trans"
                      (click)="onRemoveCourse(discNiv, cours)">
                      delete
                    </mat-icon>
                  </span>
                </div>
                <mat-icon color="accent" style="margin-left: 10px;">open_with</mat-icon>
              </div>
            </div>

          </div>
        </div>

        <div class="text-center" style="margin-top: 10px;">

          <p *ngIf="!discNiv.availableCours.length" class="text-center">{{ 'lasido.edit.no_course_message'|trans }}</p>

          <span
            *ngIf="discNiv.availableCours.length && config.coursMin && (config.coursMin > discNiv.choicesCours?.length); else maxCoursMessage">
            {{ 'lasido.edit.min_course'|trans:{min: config.coursMin} }}
          </span>

          <ng-template #maxCoursMessage>
            <ng-container *ngIf="discNiv.availableCours.length && config.coursMax">
              <span *ngIf="config.coursMax <= discNiv.choicesCours?.length; else remainingCours">
                {{ 'lasido.edit.max_course'|trans:{max: config.coursMax} }}
              </span>
              <ng-template #remainingCours>
                <span>{{ 'lasido.edit.remaining_course'|trans:{nbCours: config.coursMax - discNiv.choicesCours?.length} }}</span>
              </ng-template>
            </ng-container>
          </ng-template>

        </div>

        <div *ngIf="discNiv.availableCours?.length" fxLayout="row" fxLayoutAlign="center center"
          style="margin-top: 15px">
          <button mat-raised-button color="primary" (click)="addCours(discNiv)"
            [disabled]="!!config.coursMax && (discNiv.choicesCours?.length >= config.coursMax)"
            style="text-align: center;">
            <mat-icon>school</mat-icon>
            {{ 'lasido.edit.add_course'|trans }}
          </button>
        </div>
      </div>

      <mat-form-field *ngIf="config.enabledComment" class="no-hint" style="width: 100%; margin-top: 20px;">
        <mat-label>Commentaire</mat-label>
        <textarea matInput [(ngModel)]="discNiv.comment" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6">
          </textarea>
      </mat-form-field>
    </div>
  </ng-container>

  <div *ngIf="config.linkType && (config.url !== '0' || config.urlApiDoc !== '0')" fxLayout="row"
    fxLayoutAlign="center center" style="margin-bottom: 5px;">
    <mat-checkbox [(ngModel)]="inscription.validationReglement" (ngModelChange)="onChangeAcceptReglement($event)">
      J'accepte le règlement intérieur
    </mat-checkbox>
    <button mat-icon-button color="accent" matTooltip="Voir" (click)="viewReglementInterieur()">
      <mat-icon>visibility</mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px" style="margin-top: 20px;">
    <button mat-raised-button color="primary"
      [disabled]="(!inscription.validationReglement && (config.linkType && (config.url !== '0' || config.urlApiDoc !== '0'))) || !isInscriptionValid || saving"
      (click)="submit()">
      Valider
      <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent"
        class="icon-loader"></mat-progress-spinner>
    </button>
  </div>

</div>

<ng-template #coursSelectDialog>
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <mat-toolbar mat-dialog-title color="primary" cdkDragHandle>
      <span class="title">
        {{ 'lasido.edit.course_dialog_title'|trans }}
      </span>
      <button class="close-dialog" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div mat-dialog-content class="cours-select">

    <mat-selection-list #coursList (selectionChange)="onCoursSelectChange($event)" class="selection-list-cours">
      <mat-list-option *ngFor="let cours of coursListAvailable" [value]="cours">
        <div class="label-cours">{{ cours.libelle }}</div>
        <div *ngIf="!cours.provisoire; else coursProvisoire" class="details-cours">Le {{cours.jour}} de
          {{cours.heureDebut}} à
          {{cours.heureFin}} </div>
        <ng-template #coursProvisoire>
          <div class="details-cours">{{'lasido.text-cours-provisoire' | trans}}</div>
        </ng-template>
      </mat-list-option>
    </mat-selection-list>
    <div *ngIf="!coursListAvailable.length" style="text-align: center; margin-bottom: 22px;">
      {{ 'lasido.edit.no_course'|trans }}</div>
  </div>

  <div mat-dialog-actions align="center">
    <button mat-raised-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{ 'action.cancel'|trans }}
    </button>
    <button mat-raised-button color="primary" (click)="onValidateCoursSelection(coursList)">
      <mat-icon>check</mat-icon>
      {{ 'action.validate'|trans }}
    </button>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
