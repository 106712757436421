import { Injectable } from '@angular/core';
import { CustomHttpService } from '@app/services';
import { BehaviorSubject } from 'rxjs';
import { filter, take, map, distinctUntilChanged } from 'rxjs/operators';
import { Family, FamilyData } from '@app/models/family';
import { Adulte } from '@app/models/adulte';
import { ApiCrudService } from './api-crud.service';
import { Usager } from '@app/models/consumer';
import { ConsumerAdapter } from './adapter/consumer-adapter.service';
import { ChildAdapter } from './adapter/child-adapter.service';



const storageKey = 'currentFamily';
@Injectable({
  providedIn: 'root'
})
export class FamilyService extends ApiCrudService<Family> {
  url = 'familles';
  familyUrlPrefix = 'familles/#idFamille#/';

  private currentFamilySubject = new BehaviorSubject<Family>(this.getStoredFamily());

  currentFamily$ = this.currentFamilySubject.asObservable();

  currentFamilyReadyOnce$ = this.currentFamily$.pipe(
    filter(f => !!f),
    take(1)
  );

  currentFamilyChange$ = this.currentFamily$.pipe(
    distinctUntilChanged((x, y) => x?.id === y?.id)
  );

  reservationsToPay$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  // Not used, but could be ...
  familySwitch$ = this.currentFamily$.pipe(filter(f => !!f));

  constructor(
    protected http: CustomHttpService,
    private consumerAdapter: ConsumerAdapter,
    private childAdapter: ChildAdapter
  ) {
    super();
  }

  // Subject get / update

  get currentFamily(): Family {
    return this.currentFamilySubject.value;
  }

  setCurrentFamily(family: Family) {
    this.currentFamilySubject.next(family);
    this.storeFamily(family);
    this.reservationsToPay$.next(0);
  }

  // Local storage

  getStoredFamily(): Family {
    const family = sessionStorage.getItem(storageKey);

    return family ? JSON.parse(family) : null;
  }

  clearStoredFamily() {
    sessionStorage.removeItem(storageKey);
    localStorage.removeItem(storageKey);
  }

  storeFamily(family: Family) {
    this.clearStoredFamily();

    if (family) {
      sessionStorage.setItem(storageKey, JSON.stringify(family));
    }
  }

  // API calls

  getAdultFamilies(idAdulte) {
    return this.http.get<Family[]>(`adultes/${idAdulte}/familles`);
  }

  getFormData(id, part?) {
    part = part ? part : '';
    const url = this.getFamilyDependantUrl(id, 'form/' + part);
    return this.http.get(url, this.httpOptions);
  }

  create(data: Family | any) {
    return this.http.post(this.url, data, this.httpOptions);
  }

  update(data: Family | any, id?: number, part?: string) {
    part = part ? part : '';
    const url = this.url + (id ? '/' + id : '') + '/' + part;
    return this.http.put(url, data, this.httpOptions);
  }

  getConjointFormData(part?) {
    part = part ? part : '';
    const url = this.getCurrentFamilyDependantUrl('conjoint/' + part);
    return this.http.get(url, this.httpOptions);
  }

  createConjoint(data: Adulte | any) {
    const url = this.getCurrentFamilyDependantUrl('conjoint');
    return this.http.post(url, data, this.httpOptions);
  }

  updateConjoint(data: Adulte | any, part?: string) {
    part = part ? part : '';
    const url = this.getCurrentFamilyDependantUrl('conjoint/' + part);
    return this.http.put(url, data, this.httpOptions);
  }

  defineCurrentFamily(families: Family[]) {
    const stored = this.getStoredFamily();

    families = families.sort((a: Family, b: Family) => (a.active === b.active) ? 0 : (a.active ? -1 : 1));

    if (stored) {
      // Refresh from fresh data
      this.setCurrentFamily(families.find(f => f.id === stored.id) || families[0]);
    } else {
      const defaultFamily = families.find(f => f.active) || families[0];
      this.setCurrentFamily(defaultFamily);
    }
  }

  getFamilyDependantUrl(idFamille, url) {
    return this.familyUrlPrefix.replace('#idFamille#', idFamille) + url;
  }

  getCurrentFamilyDependantUrl(url) {
    return this.getFamilyDependantUrl(this.currentFamily.id, url);
  }

  getFamilyData(id: number) {
    return this.http.get<FamilyData>(`familles/${id}`);
  }

  getFamilyVariables(idFamily) {
    return this.http.getWithCache(`/familles/${idFamily}/summary`, 1); // 1 minute cache
  }

  getListUsagers(family: Family | number, withInscDiaExist: boolean = false) {
    const idFamily = typeof family === 'object' ? family.id : family;

    return this.http.get<Usager[]>(`familles/${idFamily}/listUsagersPortail?withInscDiaExist=${withInscDiaExist ? '1' : '0'}`).pipe(
      map(data => {
        data.forEach(u => {
          if (u.type === 'enfant') {
            u = this.childAdapter.adapt(u);
          }
        });

        return data;
      })
    );
  }

  // Plus utile... était appelé seulement pour les réservations / plannings mais désormais toutes les données sont renvoyées par l'appel "planning-data"
  // getFamilyConsumers(family: Family | number): Observable<Consumer[]> {
  //   const id = typeof family === 'object' ? family.id : family;

  //   return this.http.getWithCache<Consumer[]>(`familles/${id}/usagers`).pipe(
  //     // Adapt
  //     map((data: []) => this.adapatUsagers(data))
  //   );
  // }



  getFamilyPreInscriptions(f: Family | number, type: 'Mikado' | 'Cogito' | 'Lasido' | 'Ged') {
    const idFamille = typeof f === 'object' ? f.id : f;

    return type === "Ged" ?
      this.http.getWithCache(`familles/${idFamille}/pre-inscriptions?type=${type}`) :
      this.http.get(`familles/${idFamille}/pre-inscriptions?type=${type}`)

  }

  getFamilyInscriptions(f: Family | number, type: 'Diabolo' | 'Cogito' | 'Lasido' | 'Ged') {

    const idFamille = typeof f === 'object' ? f.id : f;

    return type === "Ged" ?
      this.http.getWithCache(`familles/${idFamille}/inscriptions?type=${type}`) :
      this.http.get(`familles/${idFamille}/inscriptions?type=${type}`)
  }
}
