import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { ActivityWithDay, PlanningService } from '@app/services/planning.service';
import { PlanningData } from '../planning/planning-data';
import { PeriodeService } from '@app/services/periode.service';
import moment from 'moment';
import { endWith, takeWhile, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { ReservationPresence } from '@app/models/reservation';
import { Periode } from '@app/models/periode';
import { PlatformService } from '@app/services';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '@app/components/_common/help-dialog/help-dialog.component';
import { commentForMonaco, commentForTinyMCE } from '@app/components/_elements/text-editor/text-editor.component';

// Either of these means disabled
type ActivitySelectError = 'date' | 'full' | 'conflict';

interface SelectActivity extends ActivityWithDay {
  grouped?: ActivityWithDay[];
  firstDate?: string;
  lastDate?: string;
  groupInfo?: string;
  datetimeDetails?: string;

  selected?: boolean;
  reserved?: boolean;
  canceled?: boolean;
  disabled?: ActivitySelectError;
  disabledCancel?: ActivitySelectError;
  reservedStatus?: string;
}

interface SelectActivityDay {
  value: string;
  activities: SelectActivity[];
}

interface SelectActivityWeek {
  value: string;
  start: Date;
  end: Date;
  days: SelectActivityDay[];
}

interface SelectActivityPeriode {
  id: number,
  name: string,
  sessions: SelectActivity[];
}

@Component({
  selector: 'app-reservation-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ReservationActivitiesComponent implements OnInit {

  @Input() data: PlanningData;

  @Output() change = new EventEmitter();
  @ViewChild('activityPeriodeDialog', { static: true }) activityPeriodeDialog: TemplateRef<any>;

  activities: SelectActivity[];
  activitiesByWeek: SelectActivityWeek[];
  activitiesByPeriode: SelectActivityPeriode[];

  periode: Periode;
  liveCapacity: boolean;

  filterText: string = '';
  showLoader = true;

  constructor(
    private periodeService: PeriodeService,
    private planningService: PlanningService,
    public platformService: PlatformService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loadData().subscribe(_ => {
      this.setupData();

      this.data.onChangePeriode$.subscribe(() => this.setupData());

      // Finaly, display all
      setTimeout(() => {
        this.showLoader = false;
        this.data.onPresenceUpdate$.subscribe(() => this.updateConflicts());
      })
    });


  }

  loadData() {
    const feries = this.data.feries ? of(null) : this.periodeService.getJoursFeries().pipe(tap(f => this.data.feries = f));

    const planningData = this.data.allDatesLoaded$.pipe(
      // As allDatesLoaded$ is a BehaviorSubject, it will never complete, so we must do something here otherwise forkjoin will never emit either.
      // The solution is 'takeWhile' wich will complete the sequence, plus 'endWith', because we must make sure to emit one value
      // (in admin, the allDatesLoaded$ will emit only one 'true' value wich will not pass after the takeWhile)
      takeWhile(x => !x),
      endWith(true)
    )
    return forkJoin([feries, planningData])
  }

  setupData() {
    this.periode = this.data.currentPeriode;
    this.liveCapacity = this.periode.liveCapacity;

    const feries = this.data.feries.map(f => f.date);
    this.data.firstEditableDate = this.planningService.computeFirstDate(moment(), this.periode.limiteSaisie, feries, this.periode);

    if (this.periode.allowCancel) {
      this.data.firstCancelableDate = this.planningService.computeFirstDate(moment(), this.periode.limiteAnnulation, feries, this.periode);
    }

    const enabledActivites = this.periode.activites.filter(a => a.enabled);

    // We can only choose activity with a Rubrique attached
    this.activities = this.planningService.getActivitiesWithDay(enabledActivites).filter(ad => ad.rubrique);

    this.activities.forEach(sa => {
      // Not sure it is still useful , but more secure 
      sa.description = sa.description.replace(commentForMonaco, '').replace(commentForTinyMCE, '').trim();
      sa.textPreview = sa.textPreview.replace(commentForMonaco, '').replace(commentForTinyMCE, '').trim();

      if (sa.group) {
        sa.grouped = this.planningService.getGroupedActivities(sa, this.activities);
        sa.groupInfo = this.getActivityGroupInfo(sa);
        sa.datetimeDetails = this.getActivityDatetimeDetails(sa);
      }
    });

    this.activities.sort((ad1, ad2) => {
      const firstDateTime = ad1.date + ad1.startTime + ad1.endTime;
      const secondDateTime = ad2.date + ad2.startTime + ad2.endTime;

      return firstDateTime === secondDateTime ? 0 : (firstDateTime > secondDateTime ? 1 : -1);
    });

    this.setReserved();
    this.setDisabled();

    switch (this.periode.modeAffichageActivite) {
      case 'periode':
        this.activitiesByPeriode = this.getActivitiesByPeriode(this.activities).sort((a, b) => a.name.localeCompare(b.name));
        break;

      case 'semaine':
      default:
        this.activitiesByWeek = this.getActivitiesByWeek(this.activities);
        break;
    }
  }

  getActivitiesByPeriode(activityDays: SelectActivity[]) {
    let activitiesGrouped: SelectActivityPeriode[] = [];

    // Utilisation de la méthode Array.reduce() pour grouper les éléments par id
    activityDays.reverse().reduce((acc, curr) => {
      // Vérification si un objet avec cet ID existe déjà dans activitiesGrouped
      const existingAct = acc.find(act => act.id === curr.id);

      if (existingAct) {
        // Si l'activite existe déjà, ajoutez simplement la nouvelle entrée dans son tableau "sessions"
        existingAct.sessions.push(curr);
      } else {
        // Sinon, créez un nouvel activite
        acc.push({
          id: curr.id,
          name: curr.label || curr.name,
          sessions: [curr]
        });
      }
      return acc;
    }, activitiesGrouped);

    activitiesGrouped.forEach(act => {
      act.sessions = act.sessions.filter((sess, index) => {
        return !sess.group || (sess.group && index === act.sessions.findIndex(actSess => actSess.id === sess.id && actSess.group === sess.group));
      })
    })

    return activitiesGrouped
  }

  get filteredActivitiesByPeriode() {
    const filter = this.removeAccents(this.filterText.toLowerCase());
    return this.activitiesByPeriode.filter(activity =>
      this.removeAccents(activity.name.toLowerCase()).includes(filter)
    );
  }

  removeAccents(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  openActivityPeriodeDialog(activityPeriode: SelectActivityPeriode) {
    const data = {
      activityPeriode
    }
    const dial = this.matDialog.open(this.activityPeriodeDialog, { data, maxWidth: 600 });

    this.platformService.adaptDialogToScreen(dial);
  }

  openDescriptionActivity(activity: SelectActivity) {
    // In case we receive an Activity without Date
    if (activity.description) {

      const date = activity.date;
      const title = this.periode.modeAffichageActivite === 'periode' ?
        (activity.label || activity.name) :
        (activity.label || activity.name) + (date ? ', le ' + moment(date).format('DD/MM/Y') : '')

      const dialog = this.matDialog.open(HelpDialogComponent, {
        maxWidth: 1000,
        data: {
          title: title,
          message: activity.description
        }
      });
      this.platformService.adaptDialogToScreen(dialog);
    }
  }

  setReserved() {
    const presences = this.planningService.filterPresences(this.data.getCurrentConsumerPresences());

    this.activities.forEach(act => {
      const pr = presences.find(pr => pr.date === act.date && pr.activities?.includes(act.id))
      if (pr) {
        act.reserved = true;
        act.reservedStatus = pr.status
      }
    });
  }

  // Only for first check
  setDisabled() {
    const fullIsError = this.periode.liveCapacity && !this.periode.gestionListeAttente;

    this.activities.forEach(a => {
      const grouped = a.grouped?.length && !a.datesFlexible ? a.grouped : [a];

      for (const ga of grouped) {
        if (!a.disabled) {
          a.disabled = this.getActivityError(ga, fullIsError);
        }

        // Ensure that "cancelable" only makes sense on already existing presences
        if (!a.disabledCancel && a.reserved && !this.data.isCancelableDate(ga.date)) {
          a.disabledCancel = 'date';
        }
      }
    });

    this.updateConflicts();
  }

  getActivityError(act: ActivityWithDay, checkCapacity: boolean): ActivitySelectError {
    if (!this.data.isEditableDate(act.date)) {
      return 'date';
    }

    if (checkCapacity && act.dispos < 1) {
      return 'full';
    }

    return null;
  }

  getActivitiesByWeek(activityDays: SelectActivity[]) {
    const weeks: SelectActivityWeek[] = [];

    for (const actDay of activityDays) {
      const date = moment(actDay.date);
      const weekValue = date.format('Y-WW');

      let week: SelectActivityWeek = weeks.find(w => w.value === weekValue);
      if (!week) {
        const weekStart = date.clone().isoWeekday(1);
        const weekEnd = date.clone().isoWeekday(7);

        week = {
          value: weekValue,
          start: weekStart.toDate(),
          end: weekEnd.toDate(),
          days: []
        };

        weeks.push(week);
      }

      // If we find the same group on the current week, just skip (this date)
      if (actDay.group && week.days.some(wd => wd.activities.some(da => da.id === actDay.id && da.group === actDay.group))) {
        continue;
      }

      let weekDay = week.days.find(d => d.value === actDay.date);
      if (!weekDay) {
        weekDay = { value: actDay.date, activities: [] };

        week.days.push(weekDay);
      }

      weekDay.activities.push(actDay);
    }

    return weeks.filter(w => w.days?.length);
  }

  // Should factorize with PlanningComp same method
  getActivityGroupInfo(activity: SelectActivity) {
    if (activity.grouped && activity.grouped.length) {
      let message = 'Cette activité se déroulera sur plusieurs jours :\n';
      message += activity.grouped.map(ad => '- ' + moment(ad.date).format('L')).join('\n');
      return message;
    }

    return null;
  }

  getActivityDatetimeDetails(activity: SelectActivity) {
    if (activity.group) {
      const session = activity.sessions.find(sess => sess.codeGroupe === activity.group);

      if (session && session.datetimeDetails) {
        return session.datetimeDetails;
      }
    }

    return null;
  }

  updateConflicts() {
    const presences = this.planningService.filterPresences(this.data.getCurrentConsumerPresences());

    // Check only activities that are not disabled by other reason than conflict
    const activities = this.activities.filter(a => !a.disabled || a.disabled === 'conflict');

    // once to "reset all"
    activities.forEach(act => act.disabled = null);

    // then do the real check
    if (!this.periode.allowSuperposedPresences) {
      activities.forEach(act => {
        const conflictPresence = presences.find(pr => pr.date === act.date && this.planningService.checkTimesOverlap(act._computedHours, this.planningService.getPresenceHours(pr)));

        if (conflictPresence) {
          act.disabled = 'conflict';

          // also tag grouped dates as 'conflict' (except if dates are flexible)
          if (act.group && !act.datesFlexible) {
            // no need to override "disabled" attribute if already set
            (act.grouped as SelectActivity[]).filter(a => !a.disabled).forEach(a => a.disabled = 'conflict');
          }
        }
      });
    }
  }

  // --- UI & Triggers --- //

  toggleActivity(act: SelectActivity) {

    const toggle = !(act.selected || act.reserved);

    let grouped = [act];

    if (act.group) {
      // exclude already selected if we toggle on, exclude not selected if we toggle off
      grouped = (act.grouped as SelectActivity[]).filter(a => toggle ? !(a.selected && a.reserved) : a.selected || a.reserved);
    }

    if (act.datesFlexible) {
      // If any date of the group is not OK, just ignore it
      grouped = grouped.filter(ga => toggle ? !ga.disabled : !ga.disabledCancel);
    } else if (grouped.some(ga => toggle ? ga.disabled : ga.disabledCancel)) {
      // Else throw an error (show error to user ?)
      console.warn('Aborted because one of the activities is disabled');
      return;
    }

    const idGroup = grouped.length > 1 ? this.planningService.generateId() : null;

    for (const ga of grouped) {
      if (toggle) {
        // First check for a Presence that canceled this Activity, if exists just remove it
        const absence = this.data.currentReservation.presences.find(pr => {
          return pr.askCancel && pr.date === ga.date && pr.activities?.includes(ga.id);
        });

        if (ga.canceled && absence) {
          this.deletePresence(absence);
          ga.reserved = true;
          ga.canceled = false;
        } else {
          const rubrique = this.periode.rubriques.find(r => r.id === ga.rubrique);
          const newPresence = this.planningService.createActivityPresence(ga, rubrique, ga.date);
          if (idGroup) {
            newPresence.group = idGroup;
          }
          this.data.addPresence(newPresence, false);
          ga.selected = true;
        }
      } else {
        // Find presence to cancel or delete, matching activity
        if (ga.selected) {
          ga.selected = false;

          const presence = this.data.currentReservation.presences.find(pr => pr.date === ga.date && pr.activities?.includes(ga.id));
          if (presence) {
            this.deletePresence(presence);
          }
        } else {
          const presence = this.data.getCurrentConsumerPresences().find(pr => pr.date === ga.date && pr.activities?.includes(ga.id));

          if (presence) {
            this.addAbsence(presence);

            // Mark every activities of the presence as canceled (unusual case, but well ...)
            presence.activities.forEach(a => {
              const pra = this.activities.find(aa => aa.date === presence.date && aa.id === a);
              pra.canceled = true;
              pra.reserved = false;
            });
          }
        }
      }
    }

    this.data.onPresenceUpdate$.next();
  }

  // @NB: duplicate with PlanningComponent, maybe move to PlanningData ?
  deletePresence(presence: ReservationPresence) {
    // If the presence is a cancel, reset the presence it was replacing
    if (presence.askCancel) {
      const replacedPresence = this.data.findPresence(presence.replacing || presence.idDominoPresence, presence.idDominoReservation);

      if (replacedPresence) {
        replacedPresence.replacedBy = null;
      }
    }

    this.data.removePresence(presence, false);
  }

  // @NB: duplicate with PlanningComponent, maybe move to PlanningData ?
  addAbsence(presence: ReservationPresence) {
    // Copy all data from Presence, except ID
    const cancelPrez = this.planningService.clonePresence(presence);
    cancelPrez.askCancel = true;
    presence.replacedBy = cancelPrez.tmpId;

    const prezReservation = this.data.findReservation(presence.reservation);

    if (prezReservation.fromDomino) {
      cancelPrez.idDominoPresence = presence.id;
      cancelPrez.idDominoReservation = presence.reservation;
    } else {
      cancelPrez.replacing = presence.id;
    }

    this.data.addPresence(cancelPrez, false);
  }
}
