import { Component, OnInit, Inject, ViewChildren, ElementRef, QueryList, AfterViewInit } from '@angular/core';
import { LocaleService } from './services/locale.service';
import { filter, map, first } from 'rxjs/operators';
import { AuthenticationService, AppUpdateService, GlobalConfigService, SessionService, ThemeService, StartupService } from '@app/services';
import moment from 'moment';
import { DOCUMENT, Location } from '@angular/common';
import { BaseConfigService, loadConf } from './services/config/base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  startPath: string = this.location.path();

  // This used to be a Subject / Observable but happens only once, so for now keep it simple
  loaded = false;

  @ViewChildren('themeWrapper') themeWrapper: QueryList<ElementRef>;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localeService: LocaleService,
    private sessionService: SessionService,
    private authService: AuthenticationService,
    private globalConfigService: GlobalConfigService,
    private appUpdateService: AppUpdateService,
    private themeService: ThemeService,
    private baseConfigService: BaseConfigService,
    private location: Location,
    private startupService: StartupService,
  ) { }

  ngOnInit() {
    moment.locale('fr');

    const dominoConnect = this.startPath.startsWith('/dominoConnect/')
    // These services must be initialized before first http request because of AuthInterceptor who need authService.currentUserValue
    this.sessionService.init(dominoConnect)
    this.authService.init()

    // Then we load (or check) the "FirstLoadConfs" 
    this.baseConfigService.initFirstLoadConfs().subscribe(() => {
      //  Now we can init the app 
      this.initApp()
    }, err => console.error("Erreur lors du chargement des configs", err))

  }

  ngAfterViewInit() {
    this.themeWrapper.changes.pipe(
      map(res => res.first),
      filter(x => !!x),
      first()
    ).subscribe(el => this.exposePrimaryColorForTimepicker(el));
  }

  initApp() {
    this.localeService.init();
    this.themeService.loadTheme()//,
    this.globalConfigService.init()

    // setTimeout(() => {  // delay this to allow time for the theme to load
    this.loaded = true;
    this.clearRootSpinner();


    // Now app is displayed, we trigger startup stuff
    this.startupService.start().subscribe(started => {
      this.appUpdateService.initNewAppChecker();
      // Finally nothing else to do here :-)
    })

  }

  exposePrimaryColorForTimepicker(el: ElementRef) {
    const themeWrapper = el.nativeElement;

    if (themeWrapper) {
      const primaryColor = getComputedStyle(themeWrapper).getPropertyValue('--primary');
      document.body.style.setProperty('--timepicker-primary', primaryColor);
    }
  }

  clearRootSpinner() {
    // Supprimer le loader de l'application
    const rootLoaderSpinner = this.document.querySelector('.root-loader-container');

    if (!!rootLoaderSpinner) {
      rootLoaderSpinner.remove();
    }
  }


}
