import { Injectable } from '@angular/core';
import {
  PresenceState, PresenceStatus, Reservation,
  ReservationError, ReservationPanierItem, ReservationPresence, ReservationStatus
} from '@app/models/reservation';
import { Observable, of } from 'rxjs';
import { finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FamilyService } from './family.service';
import { Inscription } from '@app/models/inscription';
import { Family } from '@app/models/family';
import { Facture } from '@app/models/facturation';
import { HTTP_OPTIONS_JSON } from './api-crud.service';
import { ApiPlanningData, DatesLoaded, PlanningData } from '@app/components/_user/reservation/planning/planning-data';
import { Consumer } from '@app/models/consumer';
import moment from 'moment';
import { DATE_FORMAT } from './planning.service';
import { CustomHttpService, WaitingRoomService } from '@app/services';

interface PanierFactureData {
  facture?: Facture;
  montantAvoir?: number;
  errors?: ReservationError[];
}

export function computePresenceStatus(pr: ReservationPresence, reservation?: Reservation): PresenceStatus {
  if (reservation?.state === 'Expiree') {
    return pr.askCancel ? 'expired_canceling' : 'expired';
  }

  if (pr.askCancel) {
    return 'canceling';
  }

  if (!pr.id) {
    return 'new';
  }

  switch (pr.state) {
    case PresenceState.waiting: return 'waiting';
    case PresenceState.canceled: return 'canceled';
    case PresenceState.denied: return 'denied';
    case PresenceState.accepted: return 'accepted';
    case PresenceState.attente: return 'liste_attente';
  }
}

export function translateReservationStatus(resa: Reservation): ReservationStatus {
  if (resa.otherAccount) {
    return 'other_account';
  }

  switch (resa.state) {
    case 'EnAttente': return 'waiting';
    case 'EnAttenteDePaiement': return 'waiting_payment';
    case 'Acceptee': return 'accepted';
    case 'Refusee': return 'denied';
    case 'Annulee': return 'canceled';
    case 'Expiree': return 'expired';
    case 'PartiellementAccepte': return 'partially_accepted';
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private cache_planningDataByFamily = new Map<number, PlanningData>();
  get planningData(): PlanningData {
    const idFamily = this.familyService.currentFamily.id
    if (!this.cache_planningDataByFamily.has(idFamily)) {
      this.cache_planningDataByFamily.set(idFamily, new PlanningData())
    }
    return this.cache_planningDataByFamily.get(idFamily)
  }

  constructor(
    private http: CustomHttpService,
    private familyService: FamilyService,
    private waitingRoomService: WaitingRoomService
  ) { }

  // Plus utile, était appelé seulement pour les réservations / plannings mais désormais toutes les données sont renvoyées par l'appel "planning-data"
  // getFamilyInscriptions(family: Family | number) {
  //   const id = typeof family === 'object' ? family.id : family;
  //   this.waitingRoomService.waitingForRequest$.next(true)
  //   return this.http.get<Inscription[]>(`familles/${id}/inscriptions`)
  //     .pipe(
  //       tap(_ => this.waitingRoomService.waitingForRequest$.next(false))
  //     );
  // }

  submitReservation(resa: Reservation, forSummary = false) {
    const data = {
      idPeriode: resa.idPeriode,
      idPortailPeriode: resa.idPortailPeriode,
      idInscription: resa.idInscription,
      presences: resa.presences.filter(pr => pr.attente !== -1)
    };

    const url = 'reservations' + (forSummary ? '/prevalidate' : '');

    this.waitingRoomService.waitingForRequest$.next(true)
    return this.http.post(url, data, HTTP_OPTIONS_JSON)
      .pipe(
        tap(_ => this.waitingRoomService.waitingForRequest$.next(false))
      );
  }

  getReservationCart(): Observable<ReservationPanierItem[]> {

    if (this.familyService.currentFamily) {
      this.waitingRoomService.waitingForRequest$.next(true)
      return this.http.get<ReservationPanierItem[]>(`familles/${this.familyService.currentFamily.id}/panier`).pipe(
        tap(_ => this.waitingRoomService.waitingForRequest$.next(false)),
        map(data => {
          data = data || [];

          data.forEach(item => {
            item.reservations = item.reservations.map(r => this.adaptReservation(r));
          });

          return data;
        })
      );
    }

    return of(null);
  }

  getFactureForReservations(idRegie: number, idReservations: number[]) {
    const idFamily = this.familyService.currentFamily.id;

    this.waitingRoomService.waitingForRequest$.next(true)
    return this.http.post<PanierFactureData>(`familles/${idFamily}/panier/${idRegie}/facture`, { reservations: idReservations })
      .pipe(
        tap(_ => this.waitingRoomService.waitingForRequest$.next(false))
      );
  }

  validatePanier(idRegie: number, reservations: number[], montantPaiement: number, backUrl: string) {
    const idFamily = this.familyService.currentFamily.id;

    this.waitingRoomService.waitingForRequest$.next(true)
    return this.http.post<any>(`familles/${idFamily}/panier/${idRegie}/valider`, { reservations, montantPaiement, backUrl })
      .pipe(
        tap(_ => this.waitingRoomService.waitingForRequest$.next(false))
      );
  }

  loadPlanningData(family: Family | number, dates: { start: string, end: string }, loadUsagers: boolean, forceRefresh: boolean): Observable<ApiPlanningData> {
    const idFamily = typeof family === 'object' ? family.id : family;

    let url = `reservations/planning-data?family=` + idFamily;

    if (dates) {
      // load explicit dates
      url += '&startDate=' + dates.start + '&endDate=' + dates.end;
    }

    // exclude already loaded
    if (this.planningData.loadedDates$.value?.length) {
      url += '&loaded_dates=' + JSON.stringify(this.planningData.loadedDates$.value);
    }

    // tell which periodes & reservations we already know
    const loadedReservations = this.planningData.reservations?.filter(r => r.id !== 'new')
    if (loadedReservations.length) {
      url += '&loaded_reservations=' + loadedReservations.filter(r => r.id !== 'new').map(r => r.id).join(',');
    }


    if (this.planningData.activityDetails?.length) {
      url += '&loaded_activities=' + this.planningData.activityDetails?.map(a => a.id + '-' + a.periode).join(',');
    }

    if (!this.planningData.feries?.length) {
      url += '&load_feries=1';
    }

    if (loadUsagers) {
      url += '&load_usagers=1';
    }

    const req = forceRefresh ? this.http.get<ApiPlanningData>(url) : this.http.getWithCache<ApiPlanningData>(url, 0.5)
    // ^^ cache de 30 secondes (1/2 de minutes) uniquement car ces données doivent se raffraichir vite 
    // ( de toutes façons, pas sûr que ce soit utile de passer par getWithCache ici parcequ'avec les 
    // paramètres loaded_dates | loaded_reservations | etc ... les urls ne seront pas souvent les mêmes
    // mais ça peut servir dans certains cas (pas encore de résa , ou que des résa 'fromDomino') )

    return req.pipe(
      tap(resp => {
        if (resp.reservations) {
          resp.reservations = resp.reservations.map(item => this.adaptReservation(item));
        }

        let usagersUpdated = forceRefresh || false// if forceRefresh, mean we must emit usagers too even if they not changed (to trigger refreshDisplayFilters in planning Component)
        if (resp.usagers && resp.inscriptions) {
          usagersUpdated = this.planningData.setUsagers(resp.usagers, resp.inscriptions) || loadUsagers
        }
        this.planningData.addPlanningData(resp);
        this.planningData.setLoadedDates(dates && !forceRefresh ? dates : 'all');

        this.planningData.triggerAllChanges(usagersUpdated);
      })
    );
  }

  private _planningDataLoadingProgressive$: Observable<ApiPlanningData>;
  private _firstMonthLoaded = false
  loadPlanningDataProgressive(family: Family | number): Observable<ApiPlanningData> {
    const lastFullLoad = this.planningData.allDatesLoaded$.value
    const cacheTimeout = 1000 * 60 * 5 // 5 minutes cache to avoid too frequent calls to the API
    // 
    if (lastFullLoad && (new Date()).getTime() - lastFullLoad.getTime() < cacheTimeout) {
      // console.warn('use PlanningData in cache ? / this.planningData.datesToReload?.length', this.planningData.datesToReload?.length)

      if (this.planningData.datesToReload?.length > 0) { // We have some dates to reload 

        const datesToReload = this.planningData.datesToReload.reduce((acc, cur) => {
          const start = acc.start !== '' && acc.start < cur.start ? acc.start : cur.start
          const end = acc.end !== '' && acc.end > cur.end ? acc.end : cur.end
          return { start, end }
        }, { start: '', end: '' })

        return this.refreshPartialPlanningData(family, datesToReload.start, datesToReload.end)

      } else {
        // console.warn('use PlanningData in cache => go')
        //  Les données actuelles sont OK => On emet tout de suite
        this.planningData.triggerAllChanges(true)
        return of(null)
      }
    } else {

      // Since the 'cache' mechanism above is not enough because it does not work when the user passes
      // from the ReservationsComponent to the UserReservationEditComponent before the end of the full loading 
      // => we use the same technique that in CustomHttpService.getWithCache to share this observable.
      // So this._planningDataLoading$ is the observable that can be shared for max 5 seconds after it complete
      if (this._planningDataLoadingProgressive$) {
        // we first take these observables, because if we are here, it mean that this._planningDataLoadingProgressive$
        // is running, and perhaps onChangeUsager$ has already emit via triggerAllChanges for the first month, 
        // so the component who claim the data will not receive the onChangeUsager$ since its a simple subject.

        if (this._firstMonthLoaded) {
          this.planningData.triggerAllChanges(true)
        }
        return this._planningDataLoadingProgressive$

      } else {
        // console.warn('request for PlanningData from API ', lastFullLoad, lastFullLoad ? lastFullLoad.getTime() : '', cacheTimeout)
        this.planningData.loadedDates$.next([])
        this.planningData.allDatesLoaded$.next(null)
        this._firstMonthLoaded = false;

        const firstLoad: DatesLoaded = this.planningData.getCurrentMonthBounds();
        const secondLoad: DatesLoaded = {
          start: moment(firstLoad.start).subtract(3, 'month').format(DATE_FORMAT),
          end: moment(firstLoad.end).add(3, 'month').format(DATE_FORMAT)
        };

        const switchToNextPlanningDataLoad = (conf?: DatesLoaded) => {
          if (!this.planningData.consumers?.length) {
            return of(null)
          } else {
            return this.loadPlanningData(family, conf, false, false)
          }
        }

        const timeoutInMs = 5 // mean 5ms after full loading, and after this, the cache will take over 
        this.waitingRoomService.waitingForRequest$.next(true)

        this._planningDataLoadingProgressive$ = this.loadPlanningData(family, firstLoad, true, false).pipe(
          tap(_ => {
            this._firstMonthLoaded = true;
            this.waitingRoomService.waitingForRequest$.next(false)
          }),
          // takeUntil(cancel$),
          switchMap(_ => switchToNextPlanningDataLoad(secondLoad)),
          switchMap(_ => switchToNextPlanningDataLoad()),
          finalize(() => {
            setTimeout(() => {
              this._planningDataLoadingProgressive$ = null
            }, timeoutInMs)
          }),
          shareReplay({ bufferSize: 1, windowTime: 0, refCount: false })

        );
        return this._planningDataLoadingProgressive$
      }
    }
  }

  addDatesToReload(startDate, endDate): void {
    this.planningData.datesToReload.push({ start: startDate, end: endDate })
  }

  refreshPartialPlanningData(family: Family | number, startDate, endDate): Observable<ApiPlanningData> {
    // console.log('refreshPartialPlanningData / family: ', family, 'startDate:', startDate, 'endDate', endDate)
    const reloadConf = {
      start: moment(startDate).format(DATE_FORMAT),
      end: moment(endDate).format(DATE_FORMAT)
    };
    this.planningData.loadedDates$.next([])
    this.planningData.allDatesLoaded$.next(null)

    // Remove presences between startDate and endDate, because they should be reloaded
    this.planningData.reservations.forEach(resa => {
      resa.presences = resa.presences.filter(p => p.date < startDate || p.date > endDate)
    })

    return this.loadPlanningData(family, reloadConf, false, true).pipe(
      tap(_ => {
        // Remove this refresh from datesToReload
        this.planningData.datesToReload = this.planningData.datesToReload.filter(dates => dates.start < startDate || dates.end > endDate)
      }))
  }

  adaptReservation(reservation: Reservation) {
    reservation.status = translateReservationStatus(reservation);

    if (reservation.presences) {
      for (const prez of reservation.presences) {
        if (!reservation.startDate || prez.date < reservation.startDate) { reservation.startDate = prez.date; }
        if (!reservation.endDate || prez.date > reservation.endDate) { reservation.endDate = prez.date; }
        prez.status = computePresenceStatus(prez, reservation);
      }
    }

    reservation.idConsumer = Consumer.buildIdConsumer(reservation.idChild, reservation.idAdult);
    reservation.number = typeof reservation.id === 'number' ? ('' + reservation.id).padStart(6, '0') : reservation.id;

    return reservation;
  }
}
