import { ErrorHandler, NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslateModule } from '@ngx-translate/core';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { LoaderComponent } from '@app/components/_common/loader/loader.component';
import { TransPipe } from '@app/pipes/trans.pipe';
import { RouterModule } from '@angular/router';
import { SafePipe } from '@app/pipes/safe.pipe';
import { AppSnackbarComponent } from '@app/services/snackbar.service';
import { FocusOnShowDirective } from '@app/directives/focus-on-show.directive';
import { WebdevDatePipe } from '@app/pipes/webdev-date.pipe';
import { ColorSketchModule } from 'ngx-color/sketch';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TextEditorComponent } from '@app/components/_elements/text-editor/text-editor.component';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { NotInArrayPipe } from '@app/pipes/not-in-array-type.pipe';
import { ConfirmEmailComponent } from '@app/components/_elements/confirm-email/confirm-email.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ApiUrlPipe } from '@app/pipes/api-url.pipe';
import { LoaderOverlayComponent } from '@app/components/_common/loader/overlay/overlay.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HelpDialogComponent } from '@app/components/_common/help-dialog/help-dialog.component';
import { CustomColorPipe } from '@app/pipes/custom-color.pipe';
import { CustomStyleDirective } from '@app/directives/custom-style.directive';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CodeEditorComponent } from '@app/components/_elements/code-editor/code-editor.component';
import { IconComponent } from '@app/components/_common/icon/icon.component';
import { DynamicPipe } from '@app/pipes/dynamic.pipe';
import { TimeInputComponent } from '@app/components/_elements/time-input/time-input.component';
import { KeysPipe } from '@app/pipes/keys.pipe';
import { DialogHeaderComponent } from '@app/components/_common/dialog-header/dialog-header.component';
// import { ErrorHandlerService } from '@app/services/error-handler.service';
import { TopbarActionsDirective } from '@app/directives/topbar-actions.directive';
import { SwiperModule } from 'swiper/angular';
import { ParamsLibreComponent } from '@app/components/_elements/params-libre/params-libre.component';
import { TotpCodeInput } from '@app/components/_elements/totp-code-input/totp-code-input.component';
import { QRCodeModule } from 'angularx-qrcode';
import { WaitingDialogComponent } from '@app/components/_elements/waiting-dialog.component';
import { TotpRestrictInfoComponent } from '@app/components/_elements/totp-restrict-info/totp-restrict-info.component';
import { AutoSubmitCountdownDirective } from '@app/directives/auto-submit-countdown.directive';


import '@app/pipes/date.extensions'; // doit être importé ici pour que ça fonctionne en build --prod !

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    NgxMaterialTimepickerModule.setOpts('FR-fr', 'latn'),
    FullCalendarModule,
    PdfViewerModule,
    QRCodeModule
  ],
  declarations: [
    LoaderComponent,
    LoaderOverlayComponent,
    IconComponent,
    TransPipe,
    SafePipe,
    WebdevDatePipe,
    ApiUrlPipe,
    CustomColorPipe,
    CustomStyleDirective,
    AppSnackbarComponent,
    FocusOnShowDirective,
    TopbarActionsDirective,
    TextEditorComponent,
    CodeEditorComponent,
    ConfirmDialogComponent,
    ConfirmEmailComponent,
    HelpDialogComponent,
    DialogHeaderComponent,
    NotInArrayPipe,
    DynamicPipe,
    KeysPipe,
    TimeInputComponent,
    ParamsLibreComponent,
    TotpCodeInput,
    WaitingDialogComponent,
    TotpRestrictInfoComponent,
    AutoSubmitCountdownDirective,
  ],
  // providers: [
  // { provide: ErrorHandler, useClass: ErrorHandlerService }
  // ],
  exports: [
    SwiperModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    ColorSketchModule,
    EditorModule,
    NgxMaterialTimepickerModule,
    FullCalendarModule,
    FocusOnShowDirective,
    CustomStyleDirective,
    TopbarActionsDirective,
    PdfViewerModule,
    LoaderComponent,
    LoaderOverlayComponent,
    TextEditorComponent,
    CodeEditorComponent,
    IconComponent,
    TimeInputComponent,
    DialogHeaderComponent,
    TransPipe,
    SafePipe,
    WebdevDatePipe,
    ApiUrlPipe,
    CustomColorPipe,
    NotInArrayPipe,
    DynamicPipe,
    KeysPipe,
    ParamsLibreComponent,
    TotpCodeInput,
    QRCodeModule,
    AutoSubmitCountdownDirective,
  ]
})
export class SharedModule { }
